import React from 'react';
import moment from 'moment';

import axios from 'axios';

const domain_keys = [
    { 
        domain_code : 'DALIVERY',
        domain_key : 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJkb21haW5fY29kZSI6IkRBTElWRVJZIiwiaWF0IjoiMjAyMi0wOS0wMiAwMjo1Mjo1NyIsImV4cCI6MTg1MTQ3NTk3N30.BQPIzrXs9Kj1WHVJbEdDDjQ4pSEJCUuyNBJkgvGernA'
    },
    {
        domain_code : 'WSI',        
        domain_key : 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJkb21haW5fY29kZSI6IldTSSIsImlhdCI6IjIwMjItMDktMDIgMDI6NTQ6MDkiLCJleHAiOjE4NTE0NzYwNDl9.cgRWP7yekzmbdBQM23N3n3ayKMyFBW7q7q912O9S90Q'
    }
];

async function queryParcelStatus (tracking_no, domain_key) {

    let status = 0;
    let data = {};

    try {
        let response = await axios.put(
            'https://api.x-connect.zone/locator/parcel/query', 
            { tracking_no }, 
            {
                timeout: 10000,
                crossDomain: true,
                headers: {
                    'Authorization' : 'Bearer ' + domain_key
                },
            }
        )        
        status = response.status ? response.status : 0;
        data = response.data ? response.data : {};        
        
    } catch (err) {
        console.error(err);
    }
    
    return { status, data };
}


class App extends React.Component {
    
    
    constructor(props) {
        super(props);
        
        this.state = {            
            markers : [
                { icon : 'mdi-cart-arrow-down', is_done : false },
                { icon : 'mdi-arrow-decision', is_done : false },
                { icon : 'mdi-truck-fast', is_done : false },
                { icon : 'mdi-bike-fast', is_done : false },
                { icon : 'mdi-flag-checkered', is_done : false },
            ], 
            status_list : [], 
            message : '',
            is_loaded: false,      
            tracking_no : '',    
        };
        
        this.handleChange = this.handleChange.bind(this);
        this.keyPressSearch = this.keyPressSearch.bind(this);
        this.doSearch = this.doSearch.bind(this);
    }
    
    componentDidMount() {
        
        const query = new URLSearchParams(
            window.location.search
        );
        
        let data= {};

        for (let params of query.entries()) {
            data[params[0]] = params[1];
        }
        
        if (data.trackno) {
            this.setState({ tracking_no: data.trackno }, () => {
                this.doSearch();
            })
        }
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value, data_changed : true, status_list: [], is_loaded: false});        
    }
    
    keyPressSearch(event) {
        if(event.key === 'Enter'){
            this.setState({status_list: [], message : '', is_loaded: false}, () => {
                this.doSearch();                
            })
        }    
    }  

        
    async doSearch() {
        const { tracking_no } = this.state;
        if (tracking_no.length === 0) { 
            return; 
        }

        let _got_status = false;

        
        for(let i=0; i < domain_keys.length; i++) {
            const _domain_key = domain_keys[i].domain_key;
            if (! _got_status) {
                let res = {};
                try {
                    res = await queryParcelStatus(tracking_no, _domain_key);             
                } catch (err) {
                    console.error(err);
                }
                if (res.status === 200 && res.data) {
                    const { status_list, message } = res.data;                    

                    if (status_list) {
                        let markers = [
                            { icon : 'mdi-cart-arrow-down', is_done : false },
                            { icon : 'mdi-arrow-decision', is_done : false },
                            { icon : 'mdi-truck-fast', is_done : false },
                            { icon : 'mdi-bike-fast', is_done : false },                
                            { icon : 'mdi-flag-checkered', is_done : false }
                        ];
                        
                        if (status_list.length > 0) {
                            const last_status = status_list[0];
                            const { flg_return } = last_status;
                            
                            if (flg_return) {                  
                                markers.splice(4,1);    // remove end
                                markers.push({ icon : 'mdi-cancel', is_done : false });
                                markers.push({ icon : 'mdi-truck-delivery mdi-flip-h', is_done : false });
                                markers.push({ icon : 'mdi-arrow-u-left-bottom-bold', is_done : false });
                                markers.push({ icon : 'mdi-home', is_done : false });                                        
                            }
                            
                            let mark_no = -1;
                            status_list.forEach(dat => {
                                const stage = dat.stage;
                                if (stage === 'pickup') {
                                    mark_no = Math.max(mark_no, 0);
                                } else if (stage === 'receive') {
                                    mark_no = Math.max(mark_no, 1);
                                } else if (stage === 'transport') {
                                    mark_no = Math.max(mark_no, (flg_return ? 5: 2));
                                } else if (stage === 'deliver') {
                                    mark_no = Math.max(mark_no, 3);
                                } else if (stage === 'return') {
                                    mark_no = Math.max(mark_no, 6);
                                } else if (stage === 'completed') {
                                    mark_no = Math.max(mark_no, (flg_return ? 7: 4));
                                }                   
                            });
                            
                            if (mark_no > -1) {
                                markers.forEach((dat, idx) => {
                                    markers[idx].is_done = (idx <= mark_no);
                                })                  
                            }                                                                        
                        }
        
                        this.setState({ markers, status_list});                                            
                        _got_status = true;
                    } else if (message) {
                        this.setState({ message });                                 
                    }                   
                }               
            }            
        }
        // DALIVERY : 'Authorization' : 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJkb21haW5fY29kZSI6IkRBTElWRVJZIiwiaWF0IjoiMjAyMi0wOS0wMiAwMjo1Mjo1NyIsImV4cCI6MTg1MTQ3NTk3N30.BQPIzrXs9Kj1WHVJbEdDDjQ4pSEJCUuyNBJkgvGernA'
        // WSI : 'Authorization' : 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJkb21haW5fY29kZSI6IldTSSIsImlhdCI6IjIwMjItMDktMDIgMDI6NTQ6MDkiLCJleHAiOjE4NTE0NzYwNDl9.cgRWP7yekzmbdBQM23N3n3ayKMyFBW7q7q912O9S90Q'
        /*
        axios.put(
            'https://api.x-connect.zone/locator/parcel/query', 
            { tracking_no }, 
            {
                timeout: 10000,
                crossDomain: true,
                headers: {
                    'Authorization' : 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJkb21haW5fY29kZSI6IkRBTElWRVJZIiwiaWF0IjoiMjAyMi0wOS0wMiAwMjo1Mjo1NyIsImV4cCI6MTg1MTQ3NTk3N30.BQPIzrXs9Kj1WHVJbEdDDjQ4pSEJCUuyNBJkgvGernA'
                },
            }
        )
        .then(res => {
            
            if (res.status === 200 && res.data) {
                const status_list = res.data;
                let markers = [
                    { icon : 'mdi-cart-arrow-down', is_done : false },
                    { icon : 'mdi-arrow-decision', is_done : false },
                    { icon : 'mdi-truck-fast', is_done : false },
                    { icon : 'mdi-bike-fast', is_done : false },                
                    { icon : 'mdi-flag-checkered', is_done : false }
                ];
                
                if (status_list.length > 0) {
                    const last_status = status_list[0];
                    const { flg_return } = last_status;
                    
                    if (flg_return) {                  
                        markers.splice(4,1);    // remove end
                        markers.push({ icon : 'mdi-cancel', is_done : false });
                        markers.push({ icon : 'mdi-truck-delivery mdi-flip-h', is_done : false });
                        markers.push({ icon : 'mdi-arrow-u-left-bottom-bold', is_done : false });
                        markers.push({ icon : 'mdi-home', is_done : false });                                        
                    }
                    
                    let mark_no = -1;
                    status_list.forEach(dat => {
                        const stage = dat.stage;
                        if (stage === 'pickup') {
                            mark_no = Math.max(mark_no, 0);
                        } else if (stage === 'receive') {
                            mark_no = Math.max(mark_no, 1);
                        } else if (stage === 'transport') {
                            mark_no = Math.max(mark_no, (flg_return ? 5: 2));
                        } else if (stage === 'deliver') {
                            mark_no = Math.max(mark_no, 3);
                        } else if (stage === 'return') {
                            mark_no = Math.max(mark_no, 6);
                        } else if (stage === 'completed') {
                            mark_no = Math.max(mark_no, (flg_return ? 7: 4));
                        }                   
                    });
                    
                    if (mark_no > -1) {
                        markers.forEach((dat, idx) => {
                            markers[idx].is_done = (idx <= mark_no);
                        })                  
                    }                                                                        
                }

                this.setState({ markers, status_list});                                            
            }               
        })
        .catch( err => {
            console.log(err);
        })
        .finally( () => {
            this.setState({ is_loaded: true});            
        })        
        */

        this.setState({ is_loaded: true});            
    }

    render() {
            
        const { markers, is_loaded, tracking_no, message } = this.state;
        let status_list = this.state.status_list;
        
        status_list.forEach( (dat, idx) => {
            if (dat.stage === 'completed') {
                if (dat.flg_return) {
                    status_list[idx]['icon'] = 'mdi-home';
                } else {
                    status_list[idx]['icon'] = 'mdi-flag-checkered';
                }                    
            } else if (dat.stage === 'delivery') {
                status_list[idx]['icon'] = 'mdi-bike-fast';        
            } else if (dat.stage === 'pickup') {
                status_list[idx]['icon'] = 'mdi-scooter';        
            } else if (dat.stage === 'booking') {
                status_list[idx]['icon'] = 'mdi-format-list-group';        
            } else if (dat.stage === 'receive') {
                status_list[idx]['icon'] = 'mdi-barcode-scan';        
            } else if (dat.stage === 'failed') {
                status_list[idx]['icon'] = 'mdi-cancel';        
            } else if (dat.stage === 'aborted') {
                status_list[idx]['icon'] = 'mdi-traffic-light';        
            } else if (dat.stage === 'transport') {
                status_list[idx]['icon'] = 'mdi-truck';        
            } else if (dat.stage === 'return') {
                status_list[idx]['icon'] = 'mdi-arrow-u-left-bottom-bold';        
            } else {
                status_list[idx]['icon'] = 'mdi-cube';
            }
        });
        
        return (
        <div className="h-screen container p-8 flex flex-col items-center md:max-w-lg md:mx-auto text-white">
            <div className="m-2">
                <img src="/assets/images/DALIveryLogo.png" alt="WSI Logo" className="w-48" />
            </div>
            <div className="m-2 flex flex-col text-blue-900">
                <label className="text-gray-300 text-sm my-1">Enter Tracking Number:</label>
                <input 
                    type="text" 
                    className="rounded-md border border-gray-400 p-2 w-56 shadow-xl shadow-black" 
                    name="tracking_no"
                    value={tracking_no}
                    onChange={this.handleChange}
                    onKeyPress={this.keyPressSearch}
                />        
            </div>        
            <div className="w-full mt-8 mb-16 px-8">
                { (is_loaded && status_list.length > 0) && 
                <div className="bg-gray-200 h-1 flex items-center">              
                    <div className="w-8 bg-blue-900 h-1 flex items-center place-content-center">
                        <div className="bg-blue-900 h-9 w-9 rounded-full shadow-xl flex items-center justify-center shadow-white">
                            <i className="mdi mdi-18px mdi-format-list-group"/>
                        </div>
                    </div>
                    { markers.map( (item, idx) => (
                    <div key={'stage' + idx} className={ "w-1/5 flex items-center justify-end " + (item.is_done ? ' h-1 bg-blue-900': '')}>
                        <div className={"h-9 w-9 flex items-center justify-center rounded-full  shadow-xl shadow-white text-center " + (item.is_done ? 'bg-blue-900' : 'bg-white text-wsi-blue')}>
                            <i className={"mdi mdi-18px " + item.icon}/>
                        </div>
                    </div>
                    ) )}              
                </div>      
                }
            </div>        
            <div className="w-full flex-grow md:overflow-y-auto">
                <div className="bg-white border-2 border-white rounded-lg text-blue-800 p-8 bg-opacity-70 shadow-xl shadow-black">
                    { is_loaded ? 
                    <>
                    { status_list.length ? 
                    <>
                    <div className="w-full text-center font-bold text-lg">{tracking_no}</div>
                    { status_list.map( (item, idx) => (
                    <div key={'status' + idx} className="flex flex-row flex-wrap md:flex-nowrap place-content-around items-center mb-2">
                        <div className="w-9 text-blue-800"><span className={"mdi mdi-24px " + item.icon} ></span></div>
                            <div className="w-24 border-b h-0.5 border-gray-700 border-opacity-50 rounded-sm mx-2 flex flex-col items-center place-content-center text-gray-700 text-xs">
                                <div>{moment(item.stamp).format('DD MMM YYYY')}</div>
                                <div>{moment(item.stamp).format('h:mm a')}</div>
                            </div>
                            <div className="w-56 text-sm text-blue-900 leading-none">{item.status}</div>
                        </div>
                    ))}            
                    </>
                    :
                    <>
                    { message ? 
                    <h4>{message} {tracking_no}</h4>
                    :
                    <h4>No results for {tracking_no}.</h4>
                    }
                    </>
                    }
                    </>
                    :
                    <h4 className="animate-pulse">(Enter tracking number and press Enter.)</h4> 
                    }
                </div>                    
            </div>
        </div>    
        );  
    }                    
}

export default App;
                